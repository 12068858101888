<template>
  <div
    class="widgetContainer bg-white widgetContainer--scrollable billingAddress">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{
            $t(
              getCreateCardData.id
                ? getCreateCardData.cardType === 'physical'
                  ? 'physicalCard_edit_NavTitle'
                  : 'virtualCard_edit_NavTitle'
                : getCreateCardData.cardType === 'physical'
                  ? 'physicalCard_Create_NavTitle'
                  : 'virtualCard_Create_NavTitle'
            )
          }}
        </p>
        <span class="el-icon-close cancel" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="personAddress"
        :model="formData"
        :rules="formRules">
        <div class="el-input--full-width el-input">
          <p class="wise-form__header">
            {{ $t('card_BillingAddress_HeaderTitle') }}
          </p>
          <div
            class="sameAddress"
            v-if="getCreateCardData.cardType === 'physical'">
            <p>{{ $t('card_BillingAddress_HeaderDesc') }}</p>
            <el-switch
              v-model="sameAddress"
              active-color="var(--branding-primary)"
              inactive-color="#dfdfe4" />
          </div>
          <p
            class="wise-form__header"
            v-if="getCreateCardData.cardType === 'physical'">
            {{ $t('card_ShippingAddress_HeaderTitle') }}
          </p>
          <el-form-item
            prop="address.line1"
            :rules="formRules.line1"
            class="is-no-asterisk"
            :label="$t('address_1')">
            <el-input style="display: none" />
            <el-input v-model="formData.address.line1" style="display: none" />
            <div class="el-input">
              <gmap-autocomplete
                :placeholder="$t('address_1')"
                autocomplete="disabled"
                :value="formData.address.line1"
                :options="{
                  componentRestrictions: { country: country }
                }"
                @change.native="formData.address.line1 = $event.target.value"
                @place_changed="updateAddress($event)"
                :select-first-on-enter="true"
                class="el-input__inner" />
            </div>
          </el-form-item>
        </div>

        <el-form-item prop="address.line2" :label="$t('address_2')">
          <el-input
            :placeholder="$t('address_2')"
            v-model="formData.address.addressLine2" />
        </el-form-item>
        <div class="wise-form__inline">
          <el-form-item
            prop="address.city"
            class="is-no-asterisk"
            :rules="formRules.city"
            :label="$t('address_City')">
            <el-input
              style="flex: 2"
              :placeholder="$t('address_City')"
              v-model="formData.address.city" />
          </el-form-item>
          <el-form-item
            prop="address.country"
            class="is-no-asterisk"
            :rules="formRules.state"
            :label="$t('address_State')">
            <el-input
              style="flex: 1"
              :placeholder="$t('address_State')"
              v-model="formData.address.state" />
          </el-form-item>
          <el-form-item
            prop="address.postalCode"
            class="is-no-asterisk"
            :rules="formRules.postalCode"
            :label="$t('address_Zipcode')">
            <el-input
              style="flex: 1"
              :placeholder="$t('address_Zipcode')"
              v-model="formData.address.postalCode" />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button data-testid="submit" type="primary" @click="next" class="el-button__brand brand width-100" :disabled="isFormValid">
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { formatGoogleMapData } from '@/utils/gmap';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getCountryDetailsFromPhoneNumber } from '@/utils/env';
import CardMixin from '../mixins/card';
export default {
  name: 'ContactAddressForm',
  mixins: [CardMixin],
  data() {
    return {
      formData: {
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          country: 'US',
          addressType: 'billing',
          state: null
        }
      },
      sameAddress: true,
      formRules: {
        line1: [
          {
            required: true,
            message: 'Address 1 is required'
          }
        ],
        city: [
          {
            required: true,
            message: 'City is required'
          }
        ],
        state: [
          {
            required: true,
            message: 'State is required'
          }
        ],
        postalCode: [
          {
            required: true,
            message: 'Zip Code is required'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters('user', ['getPersonDetails']),
    ...mapGetters('card', ['getCreateCardData']),
    isFormValid() {
      if (
        this.formData.address.line1 &&
        this.formData.address.city &&
        this.formData.address.postalCode
      ) {
        return false;
      } else {
        return true;
      }
    },
    country() {
      if(this.getCreateCardData.cardType === 'physical') {
        return 'US';
      }
      /** @type {import('@/modules/user/store/state').userState['personDetails']} */
      const person = this.getPersonDetails;
      const details = getCountryDetailsFromPhoneNumber(person.phone);
      if(details && details.code)
        return details.code;
      
      return 'US'

    }
  },
  created() {
    /** @type {import('@/modules/card/store/state').cardState['createCardData']} */
    const card = this.getCreateCardData;
    const user = this.getPersonDetails;

    if (card && card.id && card.billingAddress) {
      const { line1, line2, city, postalCode, state } = card.address;
      const country = this.country;
      this.formData.address = {
        line1,
        line2,
        city,
        postalCode,
        country,
        addressType: 'billing',
        state
      };
    } else if (user.address) {
      const { line1, line2, city, postalCode, state, country } = user.address;
      this.formData.address = {
        line1,
        line2,
        city,
        postalCode,
        country,
        addressType: 'billing',
        state
      };
    }
  },
  methods: {
    ...mapMutations('card', ['updateCreateCardData']),
    ...mapMutations('account', ['setSelectedCardAccount']),
    ...mapActions('card', ['createCard']),
    ...mapActions('account', ['createAccount', 'fallbackAccount']),
    updateAddress(e) {
      const address = formatGoogleMapData(e);
      this.formData.address = address;
    },
    next() {
      this.$refs.personAddress.validate((valid) => {
        if (!valid) return;

        /** @type {import('@/modules/card/store/state').cardState['createCardData']} */
        const card = {
          ...this.getCreateCardData
        };

        if (card && card.cardType === 'physical') {
          card['bin'] = 'credit';
        }

        const { line1, line2, city, postalCode, state } =
          this.formData.address;
        
        const country = this.country;
        const payload = {
          ...card,
          billingAddress: {
            line1,
            line2,
            city,
            postalCode,
            country,
            addressType: 'billing',
            state
          }
        };

        if (card.cardType === 'physical') {
          if (this.sameAddress === true) {
            payload.shipping = {
              shippingAddress: payload.billingAddress
            };
            this.update(payload);
          } else {
            this.updateCreateCardData(payload);
            setTimeout(() => {
              this.drawerPush('CardShippingAddressForm');
            }, 0);
          }
        } else {
          this.updateVirtual(payload);
        }
      });
    },
    updateVirtual(payload) {
      this.updateCreateCardData(payload);
      const loader = this.showLoader();
      delete payload.accountLabel;

      this.createCard(payload)
        .then(() => {
          this.drawerPush('CreatedSuccess');
        })
        .catch((e) => {
          console.log(e);
          loader.close();
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    update(payload) {
      this.updateCreateCardData(payload);
      const payloadCardAccount = {
        label: payload.accountLabel,
        acceptedTerms: true,
        type: 'cardAccount'
      };
      const loader = this.showLoader();

      this.createAccount(payloadCardAccount)
        .then((acc) => {
          this.setSelectedCardAccount(acc);
          const account = this.getSelectedAccount;
          const fallbackPayload = {
            accountId: acc.id,
            fallbackAccountId: account.id
          }

          this.fallbackAccount(fallbackPayload)
            .then(() => {
              delete payload.accountLabel;
              payload['accountId'] = acc.id;

              this.createCard(payload)
                .then(() => {
                  this.drawerPush('CreatedSuccess');
                })
                .catch((e) => {
                  console.log(e);
                  loader.close();
                  this.apiErrorMessage(e);
                })
                .finally(() => {
                  loader.close();
                });
            })
            .catch((e) => {
              console.log(e);
              loader.close();
              this.apiErrorMessage(e);
            });
        })
        .catch((e) => {
          console.log(e);
          loader.close();
          this.apiErrorMessage(e);
        });
    },
    cancel() {
      this.drawerClose();
    }
  }
};
</script>
<style lang="scss" scoped>
.billingAddress {
  .sameAddress {
    display: flex;
    justify-content: space-between;
    color: var(--branding);
    font-size: 17px;
    padding-bottom: 20px;

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .widgetContainer__body {
    padding-top: 32px;
  }

  .wise-form__header {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
